import React, { useRef, useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AutosizeInput from '../../components/AutosizeInput'

import './style.css'

const Home = () => {
  const Ref = useRef(null)
  const editorRef = useRef(null)
  const emailInput = useRef(null)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [sendingEmail, setSendingEmail] = useState(false)
  //
  const [copyTextName, setCopyTextName] = useState(false)
  //
  const [openModal, setOpenModal] = useState(false)
  const [showFullScreen, setShowFullScreen] = useState(false)
  const [checkBoxes, setCheckBoxes] = useState({
    flowWriting: true,
    nowEditing: true,
    // focusMode: true,
    darkMode: false,
  })
  const [userInput, setUserInput] = useState(1)
  const [fadeValue, setFadeValue] = useState('')
  const [latestTest, setLatestTest] = useState('')
  // The state for our timers
  const [timer, setTimer] = useState('00:00:00')
  const [timerEnd, setTimerEnd] = useState(false)
  const [mins, setMins] = useState(0)
  const [sec, setSec] = useState(0)
  //
  const [endTypeMins, setEndTypeMins] = useState(null)
  const [endTypeSec, setEndTypeSec] = useState(3)
  const [textParagraphCount, setTextParagraphCount] = useState(0)
  const [fadeClass, setFadeClass] = useState(false)

  //
  const getTimeRemaining = (e) => {
    const date1 = new Date()
    const total = new Date(e).getTime() - date1.getTime()
    //
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / 1000 / 60 / 60) % 24)
    setMins(minutes)
    setSec(seconds)
    return {
      total,
      hours,
      minutes,
      seconds,
    }
  }
  //
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e)
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds),
      )
    }
    if (seconds === 0 && minutes === 0 && hours === 0) {
      setTimerEnd(true)
      // let fullScreenElem = document.getElementById("TextEditor");
      // if (document.fullscreenElement) {
      //   document.exitFullscreen()
      // }
      // else if (document.Web) {
      //   /* Safari */
      //   document.webkitExitFullscreen();
      // } else if (document.msReFullscreen) {
      //   /* IE11 */
      //   document.msExitFullscreen();
      // }else if (document.mozCancelFullScreen) {
      //   /* moz */
      //   document.mozCancelFullScreen();
      // }
      // else if (document.msRequestFullscreen) {
      //   /* ms */
      //   document.msRequestFullscreen();
      // }

      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.mozRequestFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msRequestFullscreen) {
        document.msCancelFullscreen()
      }
    }
  }

  const startAllActions = () => {
    if (window.innerWidth > 768) {
      //
      if (document.documentElement.requestFullscreen) {
        
        document.documentElement.requestFullscreen().then(() => {
          editorRef.current.focus({ preventScroll: true })
          window.scrollTo(0, 0)
        })
        setTimeout(() => {
          editorRef.current.focus({ preventScroll: true })
          window.scrollTo(0, 0)
        }, 100)
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullscreen()
          editorRef.current.focus({ preventScroll: true })
          window.scrollTo(0, 0)
          console.log('open screen',timer)
          
          setTimeout(() => {
            editorRef.current.focus({ preventScroll: true })
            window.scrollTo(0, 0)
            console.log('open screen focus',timer)
        }, 50)
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
        setTimeout(() => {
          editorRef.current.focus({ preventScroll: true })
          window.scrollTo(0, 0)
        }, 100)
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen()
        setTimeout(() => {
          editorRef.current.focus({ preventScroll: true })
          window.scrollTo(0, 0)
        }, 100)
      }
    } else {
      setTimeout(() => {
        let doc = document.getElementById('currentInput')
        let textEditor = document.getElementById('TextEditor')
        if (doc) {
          doc.focus({ preventScroll: true })
          textEditor.click()
          // textEditor.focus()
          window.scrollTo(0, 0)
        }
        // editorRef.current.focus({ preventScroll: false })
      }, 700)
    }
  }
  //
  const openFullscreen = () => {
    // let fullScreenElem = document.getElementById("TextEditor");
    // if (checkBoxes.nowEditing) {
    //   document
    //     .getElementById("editor_wrapper")
    //     .addEventListener("keydown", (event) => {
    //       handleEditing(event);
    //     });
    // }
    if (Number(userInput) >= 1 && checkBoxes.flowWriting) {
      clearTimer(getDeadTime())
      new Promise((resolve) => {
        setShowFullScreen(true)
        startAllActions()
        resolve()
      }).then(() => {
        editorRef.current.focus()
        window.scrollTo(0, 0)
        console.log('promis focus',timer)
      })
    

      //       // mozilla proposal
      // document.requestFullScreen();
      // document.cancelFullScreen();

      // Webkit (works in Safari and Chrome Canary)
      // element.webkitRequestFullScreen();
      // document.webkitCancelFullScreen();

      // Firefox (works in nightly)
      // element.mozRequestFullScreen();
      // document.mozCancelFullScreen();

      // --
      // if (document.documentElement.requestFullscreen) {
      //   document.documentElement.requestFullscreen().then(() => {
      //     editorRef.current.focus({ preventScroll: false });
      //   });
      //   // document.requestFullscreen();
      // }
      //  if (document.webkitRequestFullscreen) {
      //   /* Safari */
      //   document.webkitRequestFullscreen();
      //   editorRef.current.focus({ preventScroll: false });
      // }  if (document.msRequestFullscreen) {
      //   /* IE11 */
      //   document.msRequestFullscreen();
      //   editorRef.current.focus({ preventScroll: false });
      // }
      // ----
      // if (fullScreenElem.requestFullscreen) {
      //   fullScreenElem.requestFullscreen().then(() => {
      //     editorRef.current.focus({ preventScroll: false });
      //   });
      //   // fullScreenElem.requestFullscreen();
      // } else if (fullScreenElem.webkitRequestFullscreen) {
      //   /* Safari */
      //   fullScreenElem.webkitRequestFullscreen();
      //   editorRef.current.focus({ preventScroll: false });
      // } else if (fullScreenElem.msRequestFullscreen) {
      //   /* IE11 */
      //   fullScreenElem.msRequestFullscreen();
      //   editorRef.current.focus({ preventScroll: false });
      // }
    } else if (!checkBoxes.flowWriting) {
      // clearTimer(getDeadTime())
      startAllActions()
      new Promise((resolve) => {
        setShowFullScreen(true)
        resolve()
      }).then(() => {
        editorRef.current.focus()
        window.scrollTo(0, 0)
      })
    } else {
      alert('please enter at least 1 minute')
    }
  }

  //
  const clearTimer = (e) => {
    setTimer('00:00:00')
    if (Ref.current) clearInterval(Ref.current)
    let interval
    if (mins < 0 && sec < 0) {
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        startTimer(e)
      }, 1000)
      Ref.current = interval
    }
  }

  //
  const getDeadTime = () => {
    let deadline = new Date()
    deadline.setMinutes(deadline.getMinutes() + Number(userInput));
    // deadline.setSeconds(deadline.getSeconds() + 10)
    return deadline
  }

  //
  const handleEditing = (event) => {
    if (
      checkBoxes.nowEditing &&
      (event.keyCode === 8 || event.keyCode === 46) &&
      (event.srcElement.form === null || event.srcElement.isTextEdit === false)
    ) {
      event.cancelBubble = true
      event.returnValue = false
    }
  }

  //
  const handleFadeValue = () => {
    setEndTypeMins(mins)
    setEndTypeSec(sec)
    setFadeClass(false)
  }

  //
  useEffect(() => {
    if (checkBoxes.flowWriting) {
      if (mins >= 0 && sec >= 0) {
        if (
          (endTypeMins > mins || endTypeMins === mins) &&
          endTypeSec - 3 >= sec
        ) {
          // console.log('3 sec', timer , fadeValue)
          setFadeClass(true)
        }
        if (
          (endTypeMins > mins || endTypeMins === mins) &&
          endTypeSec - 9 > sec
        ) {
          // console.log('9 sec', timer , fadeValue)
          setTextParagraphCount(0)
          setLatestTest('')
          setFadeValue('')
          setFadeClass(false)
        }
      }
    }
  }, [timer, mins, sec])

  // check/uncheck every option
  const handleCheck = (e) => {
    setCheckBoxes({
      ...checkBoxes,
      [e.target.id]: e.target.checked,
    })
  }

  //
  const updateEditor = (event) => {
    let latestText = event.target.value
    if (latestText === ' ') {
      latestText = ''
    }
    setLatestTest(latestText)
    handleFadeValue()
  }

  //
  const updateText = (event) => {
    let keyPressed = event.which
    if (keyPressed === 13 || keyPressed === 32) {
      let latestText = event.target.value + ' '
      let paragpraphIncerement = 0
      if (keyPressed === 13) {
        latestText = latestText.trimEnd() + '\n'
        paragpraphIncerement++
      }
      setFadeValue((prev) => prev + latestText)
      setLatestTest('')
      setTextParagraphCount((prev) => prev + paragpraphIncerement)
    }
  }

  //
  const handleSubmitEmail = (e) => {
    e.preventDefault()
    let emailVal = emailInput.current.value
    if (emailVal.length > 0) {
      window.open(
        `mailto:${emailVal}?subject=Flow Writer text!&body=${fadeValue}`,
        '_blank',
      )
    }
  }

  // email working
  let [emailVal, setEmailVal] = useState('')

  const handleEmail = (e) => {
    setEmailVal(e.target.value)
  }

  // email js
  const sendEmail = async (e) => {
    e.preventDefault()
    setSendingEmail(true)
    emailjs
      .send(
        // process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        // process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        'service_6bgz9ws',
        'template_f40chmn',
        {
          to: emailVal,
          to_name: `Dear`,
          from_name: 'flow writing',
          subject: 'Flow Writing',
          message: fadeValue,
          from: 'hereyouare@flowwriting.app',
          reply_to: 'hereyouare@flowwriting.app',
        },
        'KoshH-tJaMrTqI41a',
      )
      .then(() => {
        console.log('success...')
        setIsEmailSent(true)
        setEmailVal('')
        setSendingEmail(false)
      })
      .catch((err) => {
        alert('Email sending FAILED...')
        setSendingEmail(false)
        console.log('Email sending FAILED...', err)
      })
  }

  //
  const handleStartAgain = () => {
    window.location.reload()
  }

  const focusInput = () => {
    emailInput?.current?.focus()
    window.scrollTo(0, 0)
  }

  return (
    <>
      {/* welcome box */}
      {showFullScreen ? null : (
        <div
          className={`main_wrapper  ${checkBoxes.darkMode ? ' dark-mode' : ''}`}
        >
          <div className="welcome_box">
            <header>
              <h1>Welcome to flow writing</h1>
            </header>
            <div className="flow">
              <div className="flow_1">
                <input
                  id="flowWriting"
                  defaultChecked={true}
                  onChange={handleCheck}
                  type="checkbox"
                />
                <label htmlFor="flowWriting">Force Flow Writing</label>
                <div className="i_icon tooltip">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    width="22px"
                    height="22px"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>

                  <div className="tooltiptext">
                    stay in the flow! if you stop typing all process will be
                    lost after 10 seconds
                  </div>
                </div>
              </div>
              <div className="flow_1">
                <span style={{ width: '25px', height: '20px' }}></span>
                <input
                  onChange={(e) =>
                    setUserInput(e.target.value > 0 ? e.target.value : 1)
                  }
                  value={userInput}
                  className="flow_input cs-input"
                  type="number"
                  id="minutes"
                />
                <label htmlFor="minutes">minutes</label>
              </div>
            </div>
            <div className="flow">
              <div className="flow_1">
                <input
                  id="nowEditing"
                  defaultChecked={true}
                  onChange={handleCheck}
                  type="checkbox"
                />
                <label htmlFor="nowEditing">No editing</label>
                <div className="i_icon tooltip">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    width="22px"
                    height="22px"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>
                  <div className="tooltiptext">
                    disable backspace and deleting
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flow">
              <div className="flow_1">
                <input
                  id="focusMode"
                  name="focusMode"
                  defaultChecked={true}
                  onChange={handleCheck}
                  type="checkbox"
                />
                <label htmlFor="focusMode">Extra focus mode</label>
                <div className="i_icon tooltip">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    width="22px"
                    height="22px"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>

                  <div className="tooltiptext">
                    only shows you the current line you are working
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flow">
              <div className="flow_1">
                <input
                  id="darkMode"
                  name="darkMode"
                  defaultChecked={false}
                  onChange={(e) => {
                    handleCheck(e)
                    document.body.classList.toggle(
                      'dark-mode',
                      e.target.checked,
                    )
                  }}
                  type="checkbox"
                />
                <label htmlFor="darkMode">Dark mode</label>
                <div className="i_icon tooltip">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    width="22px"
                    height="22px"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>

                  <div className="tooltiptext">Dark theme for the editor</div>
                </div>
              </div>
            </div>
            <div className="flow">
              <button onClick={() => openFullscreen()} className="btn">
                Start Flow Writing
              </button>
            </div>
          </div>
        </div>
      )}

      {/* editor */}

      <div
        style={{ display: showFullScreen ? '' : 'none' }}
        className={`flow_writing_section ${
          checkBoxes.darkMode ? ' dark-mode' : ''
        }`}
        id="TextEditor"
        onClick={() => {
          openModal ? focusInput() : editorRef?.current?.focus()
        }}
        onTouchEnd={() => {
          openModal ? focusInput() : editorRef?.current?.focus()
        }}
      >
        {/* copy button */}
        {checkBoxes.flowWriting ? (
          <div
            className="top_header"
            id="timer"
            style={{ display: showFullScreen ? '' : 'none' }}
          >
            {/* {fadeValue.length > 0 && timer === "00:00:00" ? ( */}
            {timerEnd ? (
              <button
                disabled={sendingEmail}
                onClick={() => setOpenModal(!openModal)}
                className="btn"
              >
                copy text
              </button>
            ) : timer !== '00:00:00' ? (
              <div className="timer">{timer}</div>
            ) : null}
          </div>
        ) : (
          <div
            className="top_header"
            id="timer"
            style={{ display: showFullScreen ? '' : 'none' }}
          >
            <button
              disabled={sendingEmail}
              onClick={() => setOpenModal(!openModal)}
              className="btn"
            >
              copy text
            </button>
          </div>
        )}
        <div className="editor_wrapper" id="editor_wrapper">
          {fadeValue.split('\n').map((value, index) => {
            if (textParagraphCount !== index) {
              // if (checkBoxes.focusMode) {
              //   if (!timerEnd) {
              //     return (
              //       <p
              //         className={`${
              //           checkBoxes.nowEditing ? 'gray_text' : ''
              //         } renderer_para ${fadeClass ? ' fade_aniamtion_for' : ''}`}
              //         key={index}
              //         style={{ opacity: '.2' }}
              //       >
              //         {value}
              //       </p>
              //     )
              //   }
              //   if (checkBoxes.focusMode && timerEnd) {
              //     return (
              //       <p
              //         className={`${
              //           checkBoxes.nowEditing ? 'gray_text' : ''
              //         } renderer_para ${fadeClass ? ' fade_aniamtion' : ''}`}
              //         key={index}
              //         style={{ opacity: '1' }}
              //       >
              //         {value}
              //       </p>
              //     )
              //   }
              // }
              return (
                <p
                  className={`${
                    checkBoxes.nowEditing ? 'gray_text' : ''
                  } renderer_para ${fadeClass ? 'fade_aniamtion' : ''}`}
                  key={index}
                >
                  {value}
                </p>
              )
            } else {
              return (
                <p
                  className={`${
                    checkBoxes.nowEditing ? 'gray_text' : ''
                  } renderer_para ${fadeClass ? ' fade_aniamtion' : ''}`}
                  key={index}
                >
                  {/* <span className={`${fadeClass ? " fade_aniamtion" : ""}`}> */}
                  {value}
                  {/* </span> */}
                  <AutosizeInput
                    ref={editorRef}
                    onChange={updateEditor}
                    onKeyDown={updateText}
                    value={latestTest}
                  />
                </p>
              )
            }
          })}
        </div>
      </div>

      {/* modal box */}
      {openModal ? (
        <div className="modal_overlay">
          <div className="modal">
            <CopyToClipboard
              text={fadeValue}
              // onCopy={() => window.alert("Text copied!")}
            >
              <button
                className="btn"
                onClick={() => setCopyTextName(true)}
                disabled={sendingEmail}
              >
                {copyTextName ? 'Text is copied!' : 'Copy text to clipboard'}
              </button>
            </CopyToClipboard>
            <p>or email text to</p>
            {/* <form onSubmit={handleSubmitEmail}> */}
            <form onSubmit={sendEmail} style={{ textAlign: 'center' }}>
              <input
                autoFocus
                ref={emailInput}
                id="emailInput"
                type="email"
                className="cs-input"
                name="email"
                onChange={handleEmail}
                value={emailVal}
                required
                placeholder="Enter your email"
              />
              <button
                disabled={isEmailSent || sendingEmail}
                type="submit"
                className="btn"
              >
                {sendingEmail
                  ? 'sending...'
                  : isEmailSent
                  ? 'E-mail send!'
                  : 'send'}
              </button>
              <br />
              <br />
              <br />
              <button
                onClick={() => handleStartAgain()}
                disabled={sendingEmail}
                type="button"
                className="btn"
              >
                Start again
              </button>
            </form>
          </div>
        </div>
      ) : null}

      {/* <p>
    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident beatae laboriosam quaerat hic aliquid velit atque pariatur aliquam, veniam sunt magni quia sed consequatur dolores eum excepturi necessitatibus cumque adipisci ut labore corrupti. Non quaerat rem quis illo sapiente quo ab! Facilis animi natus incidunt est, fugiat in laborum perferendis, dolores accusamus, vero quaerat quas qui? Dignissimos, quaerat. Rem illum aperiam temporibus dignissimos quae. Non distinctio, veritatis laboriosam doloremque rem dolorem ex nam ullam sequi, perspiciatis ducimus sunt. Distinctio consequuntur quasi eius quod vero? Sapiente unde quam porro quibusdam perferendis quisquam beatae possimus voluptatem, aperiam, ratione consectetur amet repellendus dolor. Natus nihil quod incidunt ipsum adipisci fuga vel odio asperiores accusantium, exercitationem atque corporis deserunt sapiente voluptate et illum? Assumenda omnis quidem ipsum eaque similique perspiciatis quaerat officia quae beatae sunt dicta ut suscipit explicabo, nesciunt, inventore rem impedit repudiandae incidunt doloribus amet neque magnam. Quisquam obcaecati, aperiam quos quam iste veritatis perferendis aspernatur ut voluptatum? Iure minus hic fugit in rerum ratione, veritatis, perferendis, sequi officiis eum id ducimus. Commodi labore alias, magni, aliquam explicabo eum ab illum dolor suscipit fugit aliquid dignissimos, nisi earum adipisci facilis perspiciatis error officiis quaerat veniam quam dicta natus nihil. Aspernatur, aliquam distinctio!
   </p> */}
    </>
  )
}

export default Home
